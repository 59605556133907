import React from 'react';
import { Link } from 'gatsby';
import SEO from '../../components/SEO';
import SectionLayout from '../../layouts/SectionLayout';
import locationsImg from '../../images/locations-with-callouts.png';
import caiLogo from '../../images/cai-stacked-no-text.png';
import './index.scss';

export default function About() {
  return (
    <div className="about-section">
      <SEO title="About us" />
      <SectionLayout>
        <div style={{ width: '75px', margin: '0 auto' }}>
          <img className="img-fluid" alt="CultivateAI logo" src={caiLogo} />
        </div>
        <div id="our-company" className="mb-5">
          <h4
            className="section-display-subheader"
            style={{ marginTop: '0.5rem' }}
          >
            Our Company
          </h4>
          <div className="mobile-desktop-text-margin row">
            <div className="col-sm-12">
              <p>
                {`We understand that collecting your data is easy, but leveraging
                all of it is next to impossible. That's why our passionate team
                of agricultural software developers and service providers
                created CultivateAI. We're committed to building a cloud-based
                system that takes the work out of Ag data management, empowering
                you to make intelligent, informed, and strategic decisions.`}
              </p>
              <p>
                Our deep understanding of Precision Ag data is backed by over 15
                years of success. We have designed and built GIS and
                site-specific crop management software for small and large Ag
                operations alike—as well as for our own family farms. We
                understand first-hand how complex and challenging it can be to
                sift through volumes of data and how quickly analytics can
                become skewed.
              </p>
              <p>
                However, this is one of the most exciting times to work in
                Agriculture. The most advanced technology is now readily
                available and more affordable than ever. Our drone and satellite
                imaging allows you to observe, manage, and respond with
                unprecedented accuracy to the variability in the health of your
                crops. Our software and services are designed to help you do
                more and grow more—and use fewer resources along the way.
              </p>
            </div>
          </div>
        </div>
        <div id="locations" className="mb-5">
          <h4 className="section-display-subheader">Locations</h4>
          <div style={{ maxWidth: '800px', margin: '0 auto' }}>
            <img
              alt="map of locations"
              className="img-fluid"
              src={locationsImg}
            />
          </div>
        </div>
        <div className="text-center" id="contact">
          <h4
            className="section-display-subheader"
            style={{ marginBottom: '0.5rem' }}
          >
            <Link to="/about#contact">Contact us!</Link>
          </h4>
          <iframe
            title="about contact form"
            src="https://app.hellobonsai.com/f/f77a77b9b1f79e1?embed=true"
            frameBorder="0"
            width="100%"
            height="100%"
            style={{
              border: 'none',
              minHeight: '1500px',
            }}
          />
        </div>
      </SectionLayout>
    </div>
  );
}
